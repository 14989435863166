import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import HubspotForm from "react-hubspot-form"

export default function DownloadGPTWhitepaper() {
  return (
    <Layout
      title="Learn the Uses Cases of Generative AI To Boost your Customer Service"
      keywords="whitepaper, gpt, generative ai, customer support automation, irisgpt"
      description="Discover the automation potential of GPT engines like IrisGPT to automate customer service, quicker ticket resolutions & enhanced customer satisfaction. Learn more here."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <script
          charSet="utf-8"
          src="https://js.hsforms.net/forms/shell.js"
        ></script>
        <link
          rel="canonical"
          href="https://irisagent.com/gpt-whitepaper-download/"
        />
      </Helmet>
      <section className="inner-page" style={{ margin: "2% 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <h1>Whitepaper: Getting Started with Generative AI (GPT) for use cases in Customer Support</h1>
                <p><br/>
                   This white paper explores the use of GPT-based AI models for customer support.

 <br/><br/>We discuss how GPT engines, such as, IrisGPT, can automate key use cases, help reduce ticket response times and improve overall customer satisfaction.
                </p>
                <img
                  src="/img/irisgpt.svg"
                  className="img-fluid"
                  alt="GPT Whitepaper"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                {/* <HubspotForm
                  portalId='7494256'
                  formId='29f4e374-b150-48db-9a15-668eeac19625'
                  loading={<div>Loading...</div>}
                /> */}
                <HubspotForm
                  portalId='7494256'
                  formId='4315c9c1-bc6b-483d-8b3d-07e2b832a593'
                  loading={<div>Loading...</div>}
                />

              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
